import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import Layout from "./layout"
import Banner from "../components/banner"
import Wrapper from "../components/wrapper"

const BasicLayout = ({ children, title, width, bannerImg }) => (
  <Layout>
    <SEO title={title} />
    <Banner img={bannerImg} />
    <Wrapper style={{ position: 'relative' }} width={width}>{children}</Wrapper>
  </Layout>
)

BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bannerImg: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
}

BasicLayout.defaultProps = {
  width: "1200px",
}

export default BasicLayout
