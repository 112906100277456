import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Box = styled.div`
  max-width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: 0 auto;
  display: flex;

  & > .item {
    flex: 1;
  }

  .left {
    text-indent: 2em;
    font-size: 20px;
    line-height: 1.8em;
    padding: 18px 21px;
    border: ${({ border }) => border};
    overflow-y: scroll;
  }

  .right {
    img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    .left {
      font-size: 0.8rem;
    }
  }
`

const IntroBox = ({ img, desc, width, height, invert, border }) => (
  <Box width={width} height={height} border={border}>
    {invert ? (
      <>
        <div className="item right">
          <img src={img} alt="img"></img>
        </div>
        <div className="item left">{desc}</div>
      </>
    ) : (
      <>
        <div className="item left">{desc}</div>
        <div className="item right">
          <img src={img} alt="img"></img>
        </div>
      </>
    )}
  </Box>
)

IntroBox.propTypes = {
  img: PropTypes.string.isRequired,
  desc: PropTypes.node.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  border: PropTypes.string,
  invert: PropTypes.bool,
}

IntroBox.defaultProps = {
  width: "100%",
  height: "10rem",
  border: "1px solid #ddd",
  invert: false,
}

export default IntroBox
